import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import PT from 'prop-types'
import RRPT from 'react-router-prop-types'
import imgDownload from './images/qrcode.png'
import imgBeian from './images/beian.png'
import imgAndroid from './images/android.png'
import imgIos from './images/ios.png'
import {isMobile, isSD, isX} from '../../utils/ua'
import './index.scss'
import {androidDownloadUrl, iOSDownloadUrl} from '../../constants/def'

class Home extends React.Component {
  state = {
    contactVisible: false,
    wxQrCodeVisible: false,
  }

  componentDidMount() {}

  hideContactAlert = () => {
    this.setState({
      contactVisible: false,
    })
  }

  showContactAlert = () => {
    this.setState({
      contactVisible: true,
    })
  }

  showWxQrcode = () => {
    this.setState({
      wxQrCodeVisible: true,
    })
  }

  hideWxQrcode = () => {
    this.setState({
      wxQrCodeVisible: false,
    })
  }

  getYear = () => new Date().getFullYear()

  render() {
    const {history, userAgent, hostname} = this.props

    if (isMobile(userAgent)) {
      history.replace('/home_simple')
    } else if (isSD(hostname)) {
      history.replace('/home_sd')
    } else if (isX(hostname)) {
      history.replace('/home_x')
    }

    return (
      <div className="Home">
        <Helmet>
          <title>欢迎使用哒哒相亲App</title>
          <style>{`body{background-color: #F9F6FF;}`}</style>
        </Helmet>
        <div>
          <div className="Home__wrap">
            <div className="Home__main">
              <div className="Home__logo" />
              <div className="Home__app" />
              <div className="Home__welcome">欢迎使用哒哒相亲App</div>
              <div className="Home__slogan" />
              <div className="Home__downloadWrap">
                <div className="Home__qrcode">
                  <img src={imgDownload} alt="" />
                </div>
                <div>
                  <a className="Home__iOS" href={iOSDownloadUrl}>
                    <img src={imgIos} alt="" width="23" height="26" />
                    iPhone 下载
                  </a>
                  <a className="Home__android" href={androidDownloadUrl}>
                    <img src={imgAndroid} alt="" width="23" height="26" />
                    Android 下载
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="Home__footer">
            <div className="Home__footerBar">
              <a href="http://api.iniucha.com/cfg/user_agreement_intro">
                用户协议
              </a>
              <a href="http://api.iniucha.com/cfg/privacy_agreement_intro">
                隐私政策
              </a>
              <div
                className="Home__contact"
                onMouseLeave={this.hideContactAlert}
                onMouseEnter={this.showContactAlert}
              >
                联系我们
                {this.state.contactVisible && (
                  <div className="Home__contactWrap">
                    邮箱：iniucha@iniucha.com<br />
                    地址：北京市昌平区金域国际中心A座1303
                  </div>
                )}
              </div>
              <a href="/">|</a>
              <div
                className="Home__wechat"
                onMouseLeave={this.hideWxQrcode}
                onMouseEnter={this.showWxQrcode}
              >
                微信
                {this.state.wxQrCodeVisible && (
                  <div className="Home__wxQrcode" />
                )}
              </div>
            </div>
            <div className="Home__companyInfo">
              {`北京木可创新科技有限公司 · ${this.getYear()}`}
              <a
                href="https://beian.miit.gov.cn"
                target="_blank"
                rel="noopener noreferrer"
                className="Home__govInfo"
              >
                {' '}
                京ICP备20004234号-{this.props.hostname.endsWith('iniucha.cn')
                  ? 1
                  : 2}
              </a>
            </div>
            <div className="Home__govInfo">
              <img src={imgBeian} alt="" />
              <a
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402010792"
                target="_blank"
                rel="noopener noreferrer"
              >
                京公网安备 11011402010792号
              </a>{' '}
              增值电信业务经营许可证：京B2-20201491
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Home.propTypes = {
  history: RRPT.history.isRequired,
  userAgent: PT.string.isRequired,
  hostname: PT.string.isRequired,
}

Home.defaultProps = {}

export default connect(state => {
  const {
    env: {userAgent, hostname},
  } = state
  // console.log('state', state)
  return {userAgent, hostname}
})(Home)
